<div class="auth">
  <ngx-ui-loader [loaderId]="loaderID"></ngx-ui-loader>
  <div class="header">
    <div class="head">
      <div class="d-inline-block">{{"welcome_to_mabaat"|translate}}</div>
      <div (click)="bsModalRef.hide()" class="closeBtn"><span class="icon-Icon-ionic-ios-close"></span></div>
    </div>
    <div class="subHead">{{"sign_in_up"|translate}}</div>
  </div>
  <div class="page">
    <!-- <div class="d-flex justify-content-center mar">
      <div class="order-1" style="cursor: pointer;" (click)="step = 1" *ngIf="step == 2">
        <span class="icon-arrow-left2"></span>
      </div>
      <div class="order-2 ml-auto">
        <span class="iconMabaat icon icon-Mabaat---Green-Logo"></span>
      </div>
      <div class="order-3 ml-auto">
        <span class="icon-Icon-ionic-ios-close" (click)="bsModalRef.hide()" style="color: #768577; cursor: pointer;"></span>
      </div>
    </div>
    <div class="d-flex justify-content-center mar">
      <div class="order-1 text">
        {{"sign_in_up"|translate}}
      </div>
    </div>
    <div class="d-flex justify-content-center mar">
      <div class="order-1 smallCard mx-3" (click)="signInWithFB()">
        <span class="icon-Icon-awesome-facebook-square" style="color: #4267b2; font-size: 20px;"></span>
      </div>
      <div class="order-2 smallCard mx-3" (click)="signInWithGoogle()">
        <span class="icon-google" style="color: #f14336; font-size: 20px;"></span>
      </div>
    </div>
    <div class="separator mar">OR</div> -->

    <div *ngIf="step == 1" [@enterAnimation]>
      <form class="control_formField w-100 text-left" [formGroup]="signinForm1">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="inputLabel">{{'name'|translate}}</div>
            <mat-form-field class="example-full-width" appearance="outline">
                <input formControlName="name" type="text" matInput placeholder="{{'enter_your_fname'|translate}}">
                <mat-icon matPrefix>person_outline</mat-icon>
                <mat-error *ngIf="signinForm1Ref?.name?.errors?.required">
                  {{'name_is_require' |translate}}
                </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6 col-12 text-right">
            <div class="inputLabel text-left">{{'username'|translate}}</div>
            <mat-form-field class="example-full-width" appearance="outline">
                <input formControlName="username" type="text" matInput placeholder="{{'enter_your_lname'|translate}}">
                <mat-icon matPrefix>person_outline</mat-icon>
                <mat-error *ngIf="signinForm1Ref?.username?.errors?.required">
                  {{'username_is_require' | translate}}
                </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="inputLabel">{{'mobile'|translate}}</div>
        <div class="mb-3" >
          <app-international-phone-number (phoneValueChange)="setPhone($event)" ></app-international-phone-number>
            <mat-error class="errorInputNumber" *ngIf="signinForm1Ref?.mobile?.touched  && signinForm1Ref?.mobile?.errors?.required">
              {{'mobile_is_require' |translate}}
            </mat-error>
        </div>
        <!-- <mat-form-field class="example-full-width" appearance="outline">
            <input formControlName="mobile" type="number" matInput placeholder="{{'enter_your_phone'|translate}}">
            <span class="icon_insideInput_Prefix icon-phone" matPrefix></span>
            <mat-error *ngIf="signinForm1Ref?.mobile?.errors?.required">
              {{'mobile_is_require' |translate}}
            </mat-error>
        </mat-form-field> -->

        <div class="inputLabel">{{'email'|translate}}</div>
        <mat-form-field class="example-full-width" appearance="outline">
            <input formControlName="email" type="email" matInput placeholder="{{'enter_your_email'|translate}}">
            <span class="icon_insideInput_Prefix icon-email2" matPrefix></span>
            <mat-error *ngIf="signinForm1Ref?.email?.errors?.email && !signinForm1Ref?.email?.errors?.required">
              {{'please_enter_valid_email_address'|translate}}
            </mat-error>
            <mat-error *ngIf="signinForm1Ref?.email?.errors?.required">
              {{'email_is_require'|translate}}
            </mat-error>
        </mat-form-field>

        <div class="inputLabel">{{'password'|translate}}</div>
        <mat-form-field class="example-full-width" appearance="outline">
            <input formControlName="password" type="password" matInput placeholder="{{'enter_your_password'|translate}}">
            <span class="icon_insideInput_Prefix icon-password" matPrefix></span>
            <mat-error *ngIf="signinForm1Ref?.password?.errors?.required">
              {{'password_is_require' |translate}}
            </mat-error>
        </mat-form-field>

        <div class="inputLabel">{{'confirm_password'|translate}}</div>
        <mat-form-field class="example-full-width" appearance="outline">
            <input formControlName="confirmPassword" type="password" matInput placeholder="{{'confirm_password'|translate}}">
            <span class="icon_insideInput_Prefix icon-password" matPrefix></span>
            <mat-error *ngIf="signinForm1Ref?.confirmPassword?.errors?.required">
              {{'confirm_password_is_require' |translate}}
            </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div *ngIf="step == 2" [@enterAnimation]>
      <form class="control_formField w-100" [formGroup]="signinForm2">

        <div class="inputLabel">{{'date_of_birth'|translate}}</div>
        <mat-form-field class="example-full-width" appearance="outline" (click)="pickerDateOfBirth.open()">
          <input formControlName="dateOfBirth" placeholder="{{'date_of_birth'|translate}}" matInput [matDatepicker]="pickerDateOfBirth">
          <mat-datepicker-toggle matPrefix [for]="pickerDateOfBirth" >
            <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #pickerDateOfBirth disabled="false"></mat-datepicker>
        </mat-form-field>

        <div class="inputLabel">{{'nationality'|translate}}</div>
        <mat-form-field class="example-full-width" appearance="outline">
            <input formControlName="nationality" type="text" matInput placeholder="Enter Your Nationality">
            <mat-icon matPrefix>account_balance</mat-icon>
        </mat-form-field>

        <div class="inputLabel">{{'gender'|translate}}</div>
        <mat-form-field appearance="outline" class="example-full-width" >
          <div matPrefix>
            <mat-icon style="width:12px">accessibility_new</mat-icon>
          </div>
          <mat-select  placeholder="Enter Your gender" formControlName="gender">
            <mat-select-trigger>
              {{signinForm2Ref?.gender?.value}}
            </mat-select-trigger>
            <mat-option [value]="'male'">
              <mat-icon [ngStyle]="{'color': '#b48c57'}"> accessibility_new</mat-icon>
              {{'male'|translate}}
            </mat-option>
            <mat-option [value]="'female'">
              <mat-icon [ngStyle]="{'color': '#b48c57'}"> pregnant_woman</mat-icon>
              {{'female' |translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="inputLabel">{{'country'|translate}}</div>
        <mat-form-field class="example-full-width" appearance="outline">
            <input formControlName="country" type="text" matInput placeholder="Enter Your country">
            <mat-icon matPrefix>account_balance</mat-icon>
        </mat-form-field>

        <div class="inputLabel">{{'city'|translate}}</div>
        <mat-form-field class="example-full-width" appearance="outline">
            <input formControlName="city" type="text" matInput placeholder="{{'enter_your_city'|translate}}">
            <mat-icon matPrefix>location_city</mat-icon>
        </mat-form-field>

        <div class="inputLabel">{{'address'|translate}}</div>
        <mat-form-field class="example-full-width" appearance="outline">
            <input formControlName="address" type="text" matInput placeholder="{{'address'|translate}}">
            <mat-icon matPrefix>location_on</mat-icon>
        </mat-form-field>

      </form>
 
    </div>
      <div class="d-flex">
        <div class="order-1 textNewUser mt-auto ml-auto" >
          <div style="margin-top: 3px;">
            {{'already_have_an_account' |translate}}  <span class="create" (click)="openModallogin()">{{'login'|translate}}</span>
          </div>
        </div>
      </div>
      <div class="d-flex actions">
        <div class="order-1 w-100">
          <div class="example-button-row">
            <!-- <button [disabled]="signinForm1.invalid" *ngIf="step == 1" [@enterAnimation] class="btnBook">Next</button> -->
            <button [disabled]="signinForm1.invalid" [@enterAnimation] class="btn btnBook" (click)="signin()">{{'submit'|translate}}</button>
          </div>
        </div>
      </div>

      
      <app-social-login [loaderID]="loaderID"></app-social-login>
      
  </div>

</div>




