import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nearbyExtraFeatures'
})
export class NearbyExtraFeaturesPipe implements PipeTransform {

  transform(value: any, selectedCategory:any): any {
    var places:any = [];
    for (let i = 0; i < value?.length; i++) {
      if(value[i].value == selectedCategory){
        places = value[i].googlePlaces;
      }
    }    
    return places;
  }

}
