<div class="auth">
    <ngx-ui-loader [loaderId]="'changePasswordLoaderCode'"></ngx-ui-loader>
    <div class="header">
      <div class="head">
        <div class="d-inline-block">{{"forget_password_header"|translate}}</div>
        <div (click)="bsModalRef.hide()" class="closeBtn"><span class="icon-Icon-ionic-ios-close"></span></div>
      </div>
      <div class="subHead">{{"enter_your_code"|translate}}</div>
    </div>
    <div class="page">
      <form class="control_formField w-100 page" [formGroup]="changePasswordForm">
            <div style="direction: ltr;" class="mb-4">
              <!-- <ngx-pincode 
                  borderColor="#e6eaef" 
                  [hideChrs]="false" 
                  type="number" 
                  [size]="4" 
                  (completed)="pincodeCompleted($event)">
              </ngx-pincode> -->
              <ngx-otp-input [config]="otpInputConfig" (fill)="handleFillEvent($event)"></ngx-otp-input>

          </div>
          <div>

    

    
            <div class="d-flex flex-wrap">
              <div class="order-1 w-100 mb-2">
                <button class="send" style="background-color: #b48c57;" (click)="sendCodeAgin()">
                  <span>{{"resend_code"|translate}}</span>
                </button>
              </div>
              <div class="order-2 w-100 ml-auto">
                <button [disabled]="changePasswordForm.invalid" class="send" style="background-color: #346767;" (click)="changePassword()">
                  <span>{{"submit"|translate}}</span>
                </button>
              </div>
            </div>
      
          </div>
    </form>
    </div>
  </div>
  
  
  
  
  
  
  
  
  
  
  
  
  