import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/modules/core/api.service';
import { AuthService } from 'src/app/modules/shared/auth/auth.service';

import { SocialAuthService } from '@abacritt/angularx-social-login';
import { ApiServiceLoaderService } from 'src/app/modules/core/api-service-loader.service';
import { SigninComponent } from '../signin-modal/signin.component';
import { ForgetPasswordComponent } from '../forget-password-modal/forget-password.component';
import { VerifyComponent } from '../verify-modal/verify.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  openRate: boolean = false;
  loginForm!: UntypedFormGroup;
  loggedIn: boolean;
  loaderID: string = 'loginLoader';
  public onClose: Subject<any>;

  constructor(
    public authService: AuthService,
    public formBuilder: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public api: ApiService,
    public api_loaedr_false: ApiServiceLoaderService,
    public notifierService: NotifierService,
    public authService_Social: SocialAuthService
  ) {}

  ngOnInit(): void {
    this.onClose = new Subject();
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }
  get loginFormRef() {
    return this.loginForm.controls;
  }

  openModalSignin() {
    this.bsModalRef.hide();
    this.bsModalRef = this.modalService.show(SigninComponent, {
      class: 'authModal',
    });
  }
  openModalForgetPassword() {
    this.bsModalRef.hide();
    this.bsModalRef = this.modalService.show(ForgetPasswordComponent, {
      class: 'authModal',
    });
  }
  login() {
    this.notifierService.hideAll();
    this.api_loaedr_false
      .postData(
        'login',
        {
          email: this.loginFormRef.email.value,
          password: this.loginFormRef.password.value,
        },
        false,
        false,
        this.loaderID
      )
      .subscribe(
        (data: any) => {
          this.bsModalRef.hide();
          this.authService.login(data.object);
          this.notifierService.notify('success', data.message);
          if (this.openRate) this.onClose.next('openRate');
        },
        (err: any) => {
          if (err?.error?.object?.mustVerify) {
            this.bsModalRef.hide();
            this.notifierService.notify('warning', err.error.message);
            const initialState = {
              email: this.loginFormRef.email.value,
              fromWhere: 'login',
            };
            this.bsModalRef = this.modalService.show(VerifyComponent, {
              class: 'authModal',
              initialState,
              ignoreBackdropClick: true,
            });
          } else {
            err?.error?.validation?.forEach((element) => {
              this.notifierService.notify('error', element);
            });
          }
        }
      );
  }
}

// this.registerForm = this.formBuilder.group({
//   title: ['', Validators.required],
//   firstName: ['', Validators.required],
//   lastName: ['', Validators.required],
//   email: ['', [Validators.required, Validators.email]],
//   password: ['', [Validators.required, Validators.minLength(6)]],
//   confirmPassword: ['', Validators.required],
//   acceptTerms: [false, Validators.requiredTrue]
// }, {
//   validator: MustMatch('password', 'confirmPassword')
// });
