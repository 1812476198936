import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Error404RoutingModule } from './error-404-routing.module';
import { Error404Component } from './error404/error404.component';
import { SharedModule } from 'src/app/modules/shared/shared/shared.module';
import { ServerErrorComponent } from './server-error/server-error.component';
import { ToolbarModule } from '../../toolbar/toolbar/toolbar.module';


@NgModule({
  declarations: [Error404Component, ServerErrorComponent],
  imports: [
    CommonModule,
    Error404RoutingModule,
    SharedModule,
    ToolbarModule
  ],
  exports:[
    ServerErrorComponent
  ]
})
export class Error404Module { }
