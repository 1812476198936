import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternationalPhoneNumberComponent } from './international-phone-number.component';
import { SharedModule } from 'src/app/modules/shared/shared/shared.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';



@NgModule({
  declarations: [InternationalPhoneNumberComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgxIntlTelInputModule
  ],
  exports:[
    InternationalPhoneNumberComponent
  ]
})
export class InternationalPhoneNumberModule { }
