import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error404Component } from './error404/error404.component';
import { ServerErrorComponent } from './server-error/server-error.component';

const routes: Routes = [
  {
    path:'',
    component:Error404Component
  },
  {
    path:'server',
    component:ServerErrorComponent
  },
  {
    path:'404',
    component:Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class Error404RoutingModule { }
