<div class="auth text-left">
  <ngx-ui-loader [loaderId]="'changePasswordLoader'"></ngx-ui-loader>
  <div class="header">
    <div class="head">
      <div class="d-inline-block">{{"forget_password_header"|translate}}</div>
      <div (click)="bsModalRef.hide()" class="closeBtn"><span class="icon-Icon-ionic-ios-close"></span></div>
    </div>
    <div class="subHead">{{"enter_your_new_password"|translate}}</div>
  </div>
  <div class="page">
    <form class="control_formField w-100 page" [formGroup]="changePasswordForm">
        <div>
          <div class="inputLabel">{{'password'|translate}}</div>
          <mat-form-field class="example-full-width mt-4" appearance="outline">
              <mat-label>{{'password' |translate}} *</mat-label>
                <input formControlName="password" type="password" matInput placeholder="{{'enter_your_password'|translate}}">
                <span class="icon_insideInput_Prefix icon-password" matPrefix></span>
                <mat-error *ngIf="changePasswordFormRef?.password?.errors?.required">
                  {{'password_is_require' |translate}}
                </mat-error>
            </mat-form-field>
            <div class="inputLabel">{{'confirm_password'|translate}}</div>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>{{'confirm_password' |translate}}*</mat-label>
                <input formControlName="confirmPassword" type="password" matInput placeholder="{{'confirm_password'|translate}}">
                <span class="icon_insideInput_Prefix icon-password" matPrefix></span>
                <mat-error *ngIf="changePasswordFormRef?.confirmPassword?.errors?.required">
                  {{'confirm_password_is_require' |translate}}
                </mat-error>
            </mat-form-field>
  
          <div class="d-flex flex-wrap">
            <div class="order-1 w-100">
              <button [disabled]="changePasswordForm.invalid" class="send" style="background-color: #346767;" (click)="changePassword()">
                <span>{{"submit"|translate}}</span>
              </button>
            </div>
          </div>
    
        </div>
  </form>
  </div>
</div>












