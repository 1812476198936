import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, last, map, take } from 'rxjs/operators';
import { LanguagesService } from '../shared/services/languages.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { GlobalVariablesService } from '../base/services/global-variables.service';

@Injectable({
  providedIn: 'root',
})
export class ApiServiceLoaderService {
  public base = environment.url;
  constructor(
    private http: HttpClient,
    private languageService: LanguagesService,
    private ngxService: NgxUiLoaderService,
    private globalService: GlobalVariablesService
  ) {}

  // Api Get
  getData<T>(
    apiRoute: string,
    urlParams: any = {},
    withToken: boolean = true, //deprecated option
    loadingSnipper: boolean = false,
    componentSnipper: string = ''
  ): Observable<T> {
    if (loadingSnipper) {
      //this.spinner.show();
    }
    if (componentSnipper) {
      this.ngxService.startLoader(componentSnipper);
    }
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/plain');
    headers = headers.append('currency', localStorage.getItem('currency'));
    headers = headers.append(
      'content-language',
      this.languageService.currentLanguage$.getValue().lang
    );
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');

      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    if (localStorage.getItem('encryptedData')) {
      const encryptedData = JSON.parse(localStorage.getItem('encryptedData'));
      headers = headers.append('encryptedData', encryptedData.value);
    }
    if (this.globalService.currectLocation.getValue()) {
      headers = headers.append(
        'user-latitude',
        `${this.globalService.currectLocation.getValue().lat}`
      );
      headers = headers.append(
        'user-longitude',
        `${this.globalService.currectLocation.getValue().lng}`
      );
    }
    return this.http
      .get<T>(this.base + apiRoute, {
        headers,
        params: urlParams,
        withCredentials: localStorage.getItem('token') ? true : false, // withCred makes sure Authorization headers are sent as well
      })
      .pipe(
        take(1),
        catchError((err) => {
          if (err.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
          }
          return throwError(err);
        }),
        finalize(() => {
          if (loadingSnipper) {
            // this.spinner.hide();
          }
          if (componentSnipper) {
            this.ngxService.stopLoader(componentSnipper);
          }
        })
      );
  }

  // Api Post
  postData<T>(
    apiRoute: string,
    data: any,
    withToken: boolean = true, //deprecated option
    loadingSnipper: boolean = false,
    componentSnipper: string = '',
    customHeaders?: any
  ): Observable<T> {
    if (loadingSnipper) {
      // this.spinner.show();
    }
    if (componentSnipper) {
      this.ngxService.startLoader(componentSnipper);
    }
    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('currency', localStorage.getItem('currency'));
    headers = headers.append(
      'content-language',
      this.languageService.currentLanguage$.getValue().lang
    );
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    if (localStorage.getItem('encryptedData')) {
      const encryptedData = JSON.parse(localStorage.getItem('encryptedData'));
      headers = headers.append('encryptedData', encryptedData.value);
    }

    if (customHeaders) {
      Object.keys(customHeaders).forEach((key) => {
        const value = customHeaders[key];
        if (value == null) {
          // notice how I am using == here for type coercion
          headers = headers.delete(key); // delete the header if we specified it as null or undefined
        } else {
          headers = headers.set(key, value); // otherwise overwrite the header value even if set before
        }
      });
    }
    if (this.globalService.currectLocation.getValue()) {
      headers = headers.append(
        'user-latitude',
        `${this.globalService.currectLocation.getValue().lat}`
      );
      headers = headers.append(
        'user-longitude',
        `${this.globalService.currectLocation.getValue().lng}`
      );
    }
    const req = new HttpRequest('POST', this.base + apiRoute, data, {
      headers,
      withCredentials: localStorage.getItem('token') ? true : false,
    });

    return this.http.request<T>(req).pipe(
      map((event) => {
        if (event.type === HttpEventType.Response) {
          return event;
        }
        return null;
      }),
      last(),
      map((res) => {
        return {
          status: res,
          ...res.body,
        };
      }),
      catchError((err) => {
        if (err.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        return throwError({
          error: err.error,
        });
      }),
      finalize(() => {
        if (loadingSnipper) {
          // this.spinner.hide();
        }
        if (componentSnipper) {
          this.ngxService.stopLoader(componentSnipper);
        }
      })
    );
  }

  deleteData<T>(
    apiRoute: string,
    data: any,
    withToken: boolean = true, //deprecated option
    loadingSnipper: boolean = false,
    componentSnipper: string = '',
    customHeaders?: any
  ): Observable<T> {
    if (loadingSnipper) {
      // this.spinner.show();
    }
    if (componentSnipper) {
      this.ngxService.startLoader(componentSnipper);
    }
    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append(
      'content-language',
      this.languageService.currentLanguage$.getValue().lang
    );
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      headers = headers.append('Authorization', `Bearer ${token}`);
    }

    if (customHeaders) {
      Object.keys(customHeaders).forEach((key) => {
        const value = customHeaders[key];
        if (value == null) {
          // notice how I am using == here for type coercion
          headers = headers.delete(key); // delete the header if we specified it as null or undefined
        } else {
          headers = headers.set(key, value); // otherwise overwrite the header value even if set before
        }
      });
    }
    if (this.globalService.currectLocation.getValue()) {
      headers = headers.append(
        'user-latitude',
        `${this.globalService.currectLocation.getValue().lat}`
      );
      headers = headers.append(
        'user-longitude',
        `${this.globalService.currectLocation.getValue().lng}`
      );
    }
    const req = new HttpRequest('DELETE', this.base + apiRoute, data, {
      headers,
      withCredentials: localStorage.getItem('token') ? true : false,
    });

    return this.http.request<T>(req).pipe(
      map((event) => {
        if (event.type === HttpEventType.Response) {
          return event;
        }
        return null;
      }),
      last(),
      map((res) => {
        return {
          status: res,
          ...res.body,
        };
      }),
      catchError((err) => {
        if (err.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        return throwError({
          error: err.error,
        });
      }),
      finalize(() => {
        if (loadingSnipper) {
          // this.spinner.hide();
        }
        if (componentSnipper) {
          this.ngxService.stopLoader(componentSnipper);
        }
      })
    );
  }
}
