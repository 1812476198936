import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiServiceLoaderService } from 'src/app/modules/core/api-service-loader.service';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {

  data:any = [];
  currency:any;
  constructor(public bsModalRef: BsModalRef,private api_loaedr_false:ApiServiceLoaderService) { }

  ngOnInit(): void {
    this.currency = localStorage.getItem('currency');
   this.getData();
  }
  getData(){
    this.api_loaedr_false.getData("currencies",{},false,false,'curencyLoader').subscribe((data:any) =>{
      this.data = data;
    })
  }
  setCurrency(){
    localStorage.setItem('currency',this.currency);
    localStorage.removeItem('encryptedData');
    this.bsModalRef.hide();
  }
}
