import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeLanguageComponent } from './change-language.component';
import { SharedModule } from 'src/app/modules/shared/shared/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';



@NgModule({
    declarations: [ChangeLanguageComponent],
    imports: [
        CommonModule,
        SharedModule,
        ModalModule.forRoot(),
    ],
    exports: [
        ChangeLanguageComponent
    ]
})
export class ChangeLanguageBottomSheetModule { }
