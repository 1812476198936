import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ApiServiceLoaderService } from 'src/app/modules/core/api-service-loader.service';

@Component({
  selector: 'app-add-rating',
  templateUrl: './add-rating.component.html',
  styleUrls: ['./add-rating.component.scss']
})
export class AddRatingComponent implements OnInit {
  property:any;
  rate:any = 0;
  comments:any = '';
  returnRate:boolean = false;
  public onClose: Subject<any>;

  constructor(public bsModalRef: BsModalRef,private api_loaedr_false:ApiServiceLoaderService,private notifierService:NotifierService) { }

  ngOnInit(): void {
    this.onClose = new Subject();
  //  this.comments = this.property?.comments;
    this.rate = this.property?.rate;
  }

  sendFeedBack(){    
    let obj:any = {};
    obj['bookingId'] = this.property.booking_id;
    obj['rate'] = this.rate;
    obj['review'] = this.comments;
    this.api_loaedr_false.postData('booking/rate',obj,true,false,'ratingLoader').subscribe((data:any) =>{
      this.notifierService.notify('success', data.message);
      if(this.returnRate)  this.property['rate'] = this.rate;
      this.onClose.next(this.returnRate);
      this.bsModalRef.hide();
    },(err:any) =>{
      err.error.validation.forEach(element => {
        this.notifierService.notify('error', element);
      });
    })
  }

  onRate(e){
    this.rate = e.rating;
  }

}
