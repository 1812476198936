<div class="auth">
  <ngx-ui-loader [loaderId]="'forgetPasswordLoader'"></ngx-ui-loader>
  <div class="header">
    <div class="head">
      <div class="d-inline-block">{{"forget_password_header"|translate}}</div>
      <div (click)="bsModalRef.hide()" class="closeBtn"><span class="icon-Icon-ionic-ios-close"></span></div>
    </div>
    <div class="subHead">{{"phone_or_email"|translate}}</div>
  </div>
  <div class="page">
    <form class="control_formField w-100" [formGroup]="forgetPasswordForm">
        <mat-tab-group mat-align-tabs="center" (selectedIndexChange)="changeTab($event)">
          <mat-tab label="{{'email'|translate}}">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{'email'|translate}}</mat-label>
                  <input formControlName="email" type="email" matInput placeholder="{{'enter_your_email'|translate}}">
                  <span class="icon_insideInput_Prefix icon-email2" matPrefix></span>
                  <mat-error *ngIf="forgetPasswordFormRef?.email?.errors?.email && !forgetPasswordFormRef?.email?.errors?.required">
                    {{'please_enter_valid_email_address'|translate}}
                  </mat-error>
                  <mat-error *ngIf="forgetPasswordFormRef?.email?.errors?.required">
                    {{'email_is_require'|translate}}
                  </mat-error>
              </mat-form-field>
          </mat-tab>
          <mat-tab label="{{'phone'|translate}}">
              <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>{{'mobile'|translate}}</mat-label>
                    <input formControlName="mobile" type="number" matInput placeholder="{{'enter_your_phone'|translate}}">
                    <span class="icon_insideInput_Prefix icon-phone" matPrefix></span>
                    <mat-error *ngIf="forgetPasswordFormRef?.mobile?.errors?.required">
                      {{'mobile_is_require' |translate}}
                    </mat-error>
                    <mat-error *ngIf="forgetPasswordFormRef?.mobile?.errors?.pattern && !forgetPasswordFormRef?.mobile?.errors?.required">
                      {{"invalid_phone"|translate}}
                    </mat-error>
                </mat-form-field>
          </mat-tab>
        </mat-tab-group>
      </form>
        <div class="d-flex">
          <button (click)="sendCode()" [disabled]="forgetPasswordForm.invalid" class="send">{{"send_code"|translate}}</button>
        </div>
  </div>
</div>
  
  
  
  
  
  
  
  
  