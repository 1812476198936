import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiServiceLoaderService } from 'src/app/modules/core/api-service-loader.service';
import { VerifyChangePasswordModalComponent } from '../verify-change-password-modal/verify-change-password-modal.component';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  forgetPasswordForm: UntypedFormGroup;
  selectedTabIndex: number = 0;
  constructor(
    public bsModalRef: BsModalRef,
    private api_loaedr_false: ApiServiceLoaderService,
    private notifierService: NotifierService,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.forgetPasswordForm = this.formBuilder.group({
      type: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', []],
    });
    this.forgetPasswordForm.get('type').setValue('email');
    this.forgetPasswordForm.get('type').valueChanges.subscribe((val) => {
      if (val == 'email') {
        this.forgetPasswordForm
          .get('email')
          .setValidators([Validators.required, Validators.email]);
        this.forgetPasswordForm.get('mobile').clearValidators();
        this.forgetPasswordFormRef.mobile.setErrors(null);
      } else if (val == 'mobile') {
        this.forgetPasswordForm.get('email').clearValidators();
        this.forgetPasswordFormRef.email.setErrors(null);
        this.forgetPasswordForm
          .get('mobile')
          .setValidators([
            Validators.required,
            Validators.pattern(/^(00|0|)?(966|5|)(\d{9})$/),
          ]);
      }
      this.forgetPasswordForm.updateValueAndValidity();
    });
  }
  get forgetPasswordFormRef() {
    return this.forgetPasswordForm.controls;
  }

  sendCode() {
    this.notifierService.hideAll();
    let obj: any = {};
    obj['type'] = this.forgetPasswordFormRef.type.value;
    if (obj['type'] == 'email') {
      obj['email'] = this.forgetPasswordFormRef.email.value;
    } else if (obj['type'] == 'mobile') {
      obj['mobile'] = this.forgetPasswordFormRef.mobile.value.toString();
    }
    this.api_loaedr_false
      .postData('forgot/password', obj, false, false, 'forgetPasswordLoader')
      .subscribe(
        (data: any) => {
          this.notifierService.notify('success', data.message);
          this.openModalVerifyCode();
        },
        (err: any) => {
          err.error.validation.forEach((element) => {
            this.notifierService.notify('error', element);
          });
        }
      );
  }

  changeTab(event) {
    if (event == 0) {
      this.forgetPasswordForm.get('type').setValue('email');
      this.forgetPasswordForm
        .get('email')
        .setValue(this.forgetPasswordForm.get('email').value);
    }
    if (event == 1) {
      this.forgetPasswordForm.get('type').setValue('mobile');
      this.forgetPasswordForm
        .get('mobile')
        .setValue(this.forgetPasswordForm.get('mobile').value);
    }
    this.forgetPasswordForm.markAllAsTouched();
  }

  openModalVerifyCode() {
    this.bsModalRef.hide();
    const initialState: any = {
      forgetPasswordFormRef: this.forgetPasswordFormRef,
    };
    this.bsModalRef = this.modalService.show(
      VerifyChangePasswordModalComponent,
      { class: 'authModal', initialState, ignoreBackdropClick: true }
    );
  }
}
