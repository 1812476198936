import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared/shared/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ChangePasswordComponent } from './change-password-modal/change-password.component';
import { ForgetPasswordComponent } from './forget-password-modal/forget-password.component';
import { LoginComponent } from './login-modal/login.component';
import { SigninComponent } from './signin-modal/signin.component';
import { VerifyComponent } from './verify-modal/verify.component';
import { NgxPincodeModule } from 'ngx-pincode';
import { VerifyChangePasswordModalComponent } from './verify-change-password-modal/verify-change-password-modal.component';
import { InternationalPhoneNumberModule } from '../../children-modules/shared-component/international-phone-number/international-phone-number.module';
import { ButtonGoogleModule } from 'src/app/modules/shared/button-google/button-google.module';
import { SocialLoginComponent } from './social-login/social-login.component';
import { NgxOtpInputModule } from 'ngx-otp-input';

@NgModule({
    declarations: [
        ChangePasswordComponent,
        ForgetPasswordComponent,
        LoginComponent,
        SigninComponent,
        VerifyComponent,
        VerifyChangePasswordModalComponent,
        SocialLoginComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ModalModule.forRoot(),
        NgxPincodeModule,
        InternationalPhoneNumberModule,
        ButtonGoogleModule,
        NgxOtpInputModule
    ],
    exports: [
        ChangePasswordComponent,
        ForgetPasswordComponent,
        LoginComponent,
        SigninComponent,
        VerifyComponent,
        VerifyChangePasswordModalComponent,
        SocialLoginComponent
    ]
})
export class AuthenticationModalModuleModule { }
