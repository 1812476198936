import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddRatingComponent } from './add-rating/add-rating.component';
import { SharedModule } from 'src/app/modules/shared/shared/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { StarRatingModule } from 'angular-star-rating';
import { RerumReviewsComponent } from './rerum-reviews/rerum-reviews.component';



@NgModule({
    declarations: [AddRatingComponent, RerumReviewsComponent],
    imports: [
        CommonModule,
        SharedModule,
        StarRatingModule.forRoot(),
        ModalModule.forRoot()
    ],
    exports: [
        AddRatingComponent,
        RerumReviewsComponent
    ]
})
export class AddRatingModalModule { }
