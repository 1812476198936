import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from '../toolbar.component';
import { SharedModule } from 'src/app/modules/shared/shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AuthenticationModalModuleModule } from '../../allModals/authentication/authentication-modal-module.module';
import { HideToolbarDirective } from './hide-toolbar.directive';
import { ChangeLanguageBottomSheetModule } from '../../allModals/change-language/change-language-bottom-sheet.module';
import { HideToolbarMobileDirective } from './hide-toolbar-mobile.directive';



@NgModule({
  declarations: [ToolbarComponent, HideToolbarDirective, HideToolbarMobileDirective],
  imports: [
    CommonModule,
    SharedModule,
    BsDropdownModule.forRoot(),
    AuthenticationModalModuleModule,
    ChangeLanguageBottomSheetModule
  ],
  exports:[
    ToolbarComponent
  ]
})
export class ToolbarModule { }
