import { ElementRef, HostListener } from '@angular/core';
import { Directive } from '@angular/core';
import {  Router } from '@angular/router';

@Directive({
  selector: '[appHideToolbarMobile]'
})
export class HideToolbarMobileDirective {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showAndHide(event.target.innerWidth);
  }
  showAndHide(sizeNum){    
    if
     (
      this.route.url.includes('/faq') ||  
      this.route.url.includes('/contact_us') ||
      this.route.url.includes('/documents/upload') || 
      this.route.url.includes('/booking') || 
      this.route.url.includes('/profile') ||
      this.route.url.includes('/mobile/currency') ||
      this.route.url.includes('/error/server')
      )
    {
      if(sizeNum <= 576){
        this.elementRef.nativeElement.style.display = 'none';
      }
      else{
        this.elementRef.nativeElement.style.display = 'block';
      }
    }
    else{
      this.elementRef.nativeElement.style.display = 'block';
    }
  }
  constructor(public elementRef: ElementRef,public route:Router) { 
    this.showAndHide(window.innerWidth);
    route.events.subscribe(val => {
      this.showAndHide(window.innerWidth);
    });
  }
}
