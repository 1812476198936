import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiServiceLoaderService } from 'src/app/modules/core/api-service-loader.service';
import { ApiService } from 'src/app/modules/core/api.service';
import { AuthService } from 'src/app/modules/shared/auth/auth.service';
import { LoginComponent } from '../login-modal/login.component';
import { VerifyComponent } from '../verify-modal/verify.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('0ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class SigninComponent implements OnInit {
  step: number = 1;
  signinForm1!: UntypedFormGroup;
  signinForm2!: UntypedFormGroup;
  loaderID: string = 'signinLoader';
  constructor(
    public authService: AuthService,
    public formBuilder: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public api: ApiService,
    public api_loaedr_false: ApiServiceLoaderService,
    public notifierService: NotifierService
  ) {}

  ngOnInit(): void {
    this.signinForm1 = this.formBuilder.group({
      name: ['', [Validators.required]],
      username: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
    this.signinForm2 = this.formBuilder.group({
      dateOfBirth: [{ value: '', disabled: true }, []],
      nationality: ['', []],
      gender: ['', []],
      country: ['', []],
      city: ['', []],
      address: ['', []],
    });
  }
  get signinForm1Ref() {
    return this.signinForm1.controls;
  }
  get signinForm2Ref() {
    return this.signinForm2.controls;
  }

  setPhone(event) {
    this.signinForm1.get('mobile').setValue(event?.e164Number);
    if (event) {
      this.signinForm1.get('mobile').markAsTouched();
      this.signinForm1.get('mobile').updateValueAndValidity();
    }
  }

  openModallogin() {
    this.bsModalRef.hide();
    this.bsModalRef = this.modalService.show(LoginComponent, {
      class: 'authModal',
    });
  }

  signin() {
    this.notifierService.hideAll();
    this.api_loaedr_false
      .postData(
        'register',
        {
          name: this.signinForm1Ref.name.value,
          surname: this.signinForm1Ref.username.value,
          mobile: this.signinForm1Ref.mobile.value.toString(),
          email: this.signinForm1Ref.email.value,
          password: this.signinForm1Ref.password.value,
          password_confirmation: this.signinForm1Ref.confirmPassword.value,

          birth_date: this.signinForm2Ref.dateOfBirth.value,
          nationality: this.signinForm2Ref.nationality.value,
          gender: this.signinForm2Ref.gender.value == 'male' ? 1 : 0,
          country: this.signinForm2Ref.country.value,
          city: this.signinForm2Ref.city.value,
          address: this.signinForm2Ref.address.value,
        },
        false,
        true,
        this.loaderID
      )
      .subscribe(
        (data: any) => {
          this.bsModalRef.hide();
          this.notifierService.notify('warning', data.message);
          const initialState = {
            email: data.object.email,
            fromWhere: 'signin',
          };
          this.bsModalRef = this.modalService.show(VerifyComponent, {
            class: 'authModal',
            initialState,
            ignoreBackdropClick: true,
          });
        },
        (err: any) => {
          err.error.validation.forEach((element) => {
            this.notifierService.notify('error', element);
          });
        }
      );
  }
}
