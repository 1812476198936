<div style="position: relative;">
    <ngx-ui-loader [loaderId]="'ratingLoader'"></ngx-ui-loader>
    <div class="webView">
        <div class="myRating">
            <div class="header">
              <div class="head">
                <div class="d-inline-block">{{"how_was_your_staying"|translate}}</div>
                <div (click)="bsModalRef.hide()" class="closeBtn"><span class="icon-Icon-ionic-ios-close"></span></div>
              </div>
              <div class="subHead">{{"please_rate"|translate}} {{property?.property_name}}</div>
            </div>
            <div class="page text-center">
                <!-- <star-rating  [dir]="'ltr'" [value]="rate" [totalstars]="5" checkedcolor="#ffaf4e" uncheckedcolor="#DCDCDC" size="40px" [readonly]="false" (rate)="onRate($event)"></star-rating> -->
                <star-rating [hoverEnabled]="true"  [showHalfStars]="true" [rating]="rate" [size]="'large'" [readOnly]="false" [direction]="'ltr'" [speed]="'slow'" (starClickChange)="onRate($event)"></star-rating>

                <textarea [(ngModel)]="comments" name="" id="" placeholder="{{'i_love_the_place'| translate}}"></textarea>
                <button [disabled]="!rate" class="feedback" (click)="sendFeedBack()">
                    <span>{{'send_feedback' | translate}}</span>
                  </button>
            </div>
          </div>
    </div>
    
    <div class="mobileView text-left">
        <div class="header">
            <div (click)="bsModalRef.hide()" class="closeBtn text-right"><span class="icon-Icon-ionic-ios-close"></span></div>
            <div class="head">
                  <div>{{"how_was_your_staying"|translate}}</div>
                </div>
                <div class="subHead">
                   {{"please_rate"|translate}} {{property?.property_name}}
                </div>
              </div>
              <hr>
              <div class="page">
                    <!-- <star-rating  [dir]="'ltr'" [value]="rate" [totalstars]="5" checkedcolor="#ffaf4e" uncheckedcolor="#DCDCDC" size="40px" [readonly]="false" (rate)="onRate($event)"></star-rating> -->
                    <star-rating [hoverEnabled]="true"  [showHalfStars]="true" [rating]="rate" [size]="'large'" [readOnly]="false" [direction]="'ltr'" [speed]="'slow'" (starClickChange)="onRate($event)"></star-rating>

                    <textarea [(ngModel)]="comments" name="" id="" placeholder="{{'i_love_the_place'| translate}}"></textarea>
            </div>
    </div>
</div>

<div class="mobileView actions">
    <button [disabled]="!rate" class="feedback" (click)="sendFeedBack()">
        <span>{{'send_feedback' | translate}}</span>
    </button>
   </div>
  
  
  
  
  
  
  
  
  
  