import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { LanguagesService } from 'src/app/modules/shared/services/languages.service';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss']
})
export class ChangeLanguageComponent implements OnInit {
  direction:any;
  checkEnglish:boolean = true;
  constructor(private _bottomSheetRef: MatBottomSheetRef<ChangeLanguageComponent>,private lang:LanguagesService) { }

  ngOnInit(): void {
    this.lang.getCurrentLanguage().subscribe(data =>{
      this.direction = data.dir;
      if(this.direction == 'ltr') this.checkEnglish = true;
      else this.checkEnglish = false;
     });
  }


  changeLanguage(l:string){
    this.checkEnglish = !this.checkEnglish;
    this.lang.changeLanguage(l);
  }

  
  closeSheet(event: MouseEvent): void{
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

}
