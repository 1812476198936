<div class="serverError">
  <div class="toolbarFixed">
    <app-toolbar
      [scrollable]="false"
      [toolbarBooleanMobile]="false"
      [appBarBoolean]="false"
    ></app-toolbar>
  </div>
</div>

<div class="pageNotFound">
  <div class="page">
    <div
      class="d-flex justify-content-center align-items-center"
      style="flex-direction: column"
    >
      <img
        src="https://mabaat-qa-frontend.azureedge.net/mabaat-angular/final_assets/assets/images/serverError.png"
      />
      <div class="order-2 head">{{ "serverError_title" | translate }}</div>
      <div class="order-3 desc">
        {{ "serverError_description" | translate }}
      </div>
    </div>
  </div>
</div>
