import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiServiceLoaderService } from 'src/app/modules/core/api-service-loader.service';
import { ChangePasswordComponent } from '../change-password-modal/change-password.component';

@Component({
  selector: 'app-verify-change-password-modal',
  templateUrl: './verify-change-password-modal.component.html',
  styleUrls: ['./verify-change-password-modal.component.scss'],
})
export class VerifyChangePasswordModalComponent implements OnInit {
  changePasswordForm: UntypedFormGroup;
  forgetPasswordFormRef: any;
 
  otpInputConfig = {
    otpLength: 4, // Specify the length of the OTP
    autofocus: true, // Set to true if you want to allow only numbers
    isPasswordInput: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };
  constructor(
    public bsModalRef: BsModalRef,
    private api_loaedr_false: ApiServiceLoaderService,
    private notifierService: NotifierService,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      code: ['', [Validators.required]],
    });
  }

  get changePasswordFormRef() {
    return this.changePasswordForm.controls;
  }

  changePassword() {
    this.notifierService.hideAll();
    let obj: any = {};
    obj['code'] = this.changePasswordFormRef.code.value;
    if (this.forgetPasswordFormRef.type.value == 'email') {
      obj['email'] = this.forgetPasswordFormRef.email.value;
    } else if (this.forgetPasswordFormRef.type.value == 'mobile') {
      obj['mobile'] = this.forgetPasswordFormRef.mobile.value.toString();
    }
    this.api_loaedr_false
      .postData(
        'verify/forgot/password/code',
        obj,
        false,
        false,
        'changePasswordLoaderCode'
      )
      .subscribe(
        (data: any) => {
          this.openModalChangePassword();
          this.notifierService.notify('success', data.message);
          this.bsModalRef.hide();
        },
        (err: any) => {
          err.error.validation.forEach((element) => {
            this.notifierService.notify('error', element);
          });
        }
      );
  }

  sendCodeAgin() {
    this.notifierService.hideAll();
    let obj: any = {};
    if (this.forgetPasswordFormRef.type.value == 'email') {
      obj['email'] = this.forgetPasswordFormRef.email.value;
    } else if (this.forgetPasswordFormRef.type.value == 'mobile') {
      obj['mobile'] = this.forgetPasswordFormRef.mobile.value.toString();
    }
    this.api_loaedr_false
      .postData('resend/code', obj, false, false, 'changePasswordLoaderCode')
      .subscribe(
        (data: any) => {
          this.notifierService.notify('success', data.message);
        },
        (err: any) => {
          err.error.validation.forEach((element) => {
            this.notifierService.notify('error', element);
          });
        }
      );
  }

  openModalChangePassword() {
    this.bsModalRef.hide();
    const initialState: any = {
      forgetPasswordFormRef: this.forgetPasswordFormRef,
      code: this.changePasswordFormRef.code.value,
    };
    this.bsModalRef = this.modalService.show(ChangePasswordComponent, {
      class: 'authModal',
      initialState,
      ignoreBackdropClick: true,
    });
  }

  pincodeCompleted(code) {
    this.changePasswordFormRef.code.setValue(code);
    this.changePassword();
  }


  handeOtpChange(value: string[]): void {
    console.log(value);
  }

  handleFillEvent(value: string): void {
    this.changePasswordFormRef.code.setValue(value);
    this.changePassword();
  }
  
}
