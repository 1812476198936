<div class="sortBySheet text-left">
    <div class="closeBtn" (click)="closeSheet($event)"></div>
    <div class="head">
        <div class="text">{{"change_the_language"|translate}}</div>
        <div class="x_close">
            <span (click)="closeSheet($event)" class="icon-Icon-ionic-ios-close" style="font-size: 14px;color: #5B6772;"></span>
        </div>
    </div>
    <hr>

    <label class="container" (click)="changeLanguage('en')">
        <span class="text" [ngClass]="{'checedClass': direction == 'ltr'}">English</span>
        <input type="checkbox" [checked]="checkEnglish">
        <span class="checkmark">
            <span class="icon-checkTrue"></span>
        </span>
      </label>
      <hr>
      <label class="container" (click)="changeLanguage('ar')">
        <span class="text" [ngClass]="{'checedClass': direction == 'rtl'}">العــربية</span>
        <input type="checkbox" [checked]="!checkEnglish">
        <span class="checkmark">
            <span class="icon-checkTrue"></span>
        </span>
      </label>

</div>