import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GoogleButtonService } from '../services/google-button.service';

@Component({
  selector: 'app-button-google',
  templateUrl: './button-google.component.html',
  styleUrls: ['./button-google.component.scss']
})
export class ButtonGoogleComponent implements OnInit {
  @ViewChild('div', { static: true }) div: ElementRef;
  constructor(private renderer: Renderer2, private googleButtonService:GoogleButtonService) {}

  ngOnInit() {
    if(!this.googleButtonService.sahredGoogleButton.getValue()){
      this.googleButtonService.sahredGoogleButton.next(this.googleButtonService.sahredGoogleButtonGlobal.getValue());
    }
    this.renderer.appendChild(this.div.nativeElement, this.googleButtonService.sahredGoogleButton.getValue());
  }
}
