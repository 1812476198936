<div class="pageNotFound">
  <div class="page">
    <div class="iconLogo">
      <div class="d-flex" style="justify-content: flex-end">
        <div class="order-1 mx-4">
          <span
            [routerLink]="['/home']"
            style="color: #000; font-size: 14px; cursor: pointer"
            class="icon-Icon-ionic-ios-close"
          ></span>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
      style="flex-direction: column"
    >
      <img
        src="https://mabaat-qa-frontend.azureedge.net/mabaat-angular/final_assets/assets/images/page_not_found.png"
      />
      <div class="order-2 head">{{ "404" | translate }}</div>
      <div class="order-3 desc">
        {{ "go_home_and_explore" | translate }}
      </div>
    </div>
  </div>
</div>
