import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HrefIDsService {
  private subject = new Subject<any>();
  constructor(){
    this.send(false);
  }

    send(check: boolean) {
        this.subject.next(check);
    }

    clear() {
        this.subject.next(false);
    }

    get(): Observable<any> {
        return this.subject.asObservable();
    }
}
