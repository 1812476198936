import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  defaultLanguage: any = "ar";
  check:boolean = false;
  public currentLanguage$: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(private translate: TranslateService ,private router:Router,private route:ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      if(params?.lang == 'en') localStorage.setItem("lang", "en");
      if(params?.lang == 'ar') localStorage.setItem("lang", "ar");
      this.defaultLanguage = localStorage.getItem('lang');
      if(!this.defaultLanguage){
        localStorage.setItem("lang", "ar");
        this.defaultLanguage = "ar";
        this.currentLanguage$.next({lang:'ar',dir:'ltr'});
      }
      this.translate.setDefaultLang(this.defaultLanguage);
      this.translate.use(this.defaultLanguage);
  
      if (this.defaultLanguage == 'en') {
        this.currentLanguage$.next({lang:this.defaultLanguage,dir:'ltr'});
        document.documentElement.setAttribute('dir', 'ltr');
      }
      else if (this.defaultLanguage == 'ar'){
        this.currentLanguage$.next({lang:this.defaultLanguage,dir:'rtl'});
        document.documentElement.setAttribute('dir', 'rtl');
      }
    });
  }

  changeLanguage(lang:any){
    if(lang != localStorage.setItem('lang',lang)){
      localStorage.setItem('lang',lang);
      window.location.reload();
    }
  }

  getCurrentLanguage(): Observable<any> {
    return this.currentLanguage$.asObservable();
  }
  get getLang(){
    return this.translate.currentLang;
  }
  
    reloadCurrentRoute() {
    let currentUrl = this.router.url;
    //currentUrl == '/home'
    if(currentUrl == '/') {
      currentUrl = '/home';
    }
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
   }
}
