import {
  FacebookLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiServiceLoaderService } from 'src/app/modules/core/api-service-loader.service';
import { AuthService } from 'src/app/modules/shared/auth/auth.service';
import { GoogleButtonService } from 'src/app/modules/shared/services/google-button.service';
import { GlobalVariablesService } from '../../../services/global-variables.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss'],
})
export class SocialLoginComponent implements OnInit, OnDestroy {
  @ViewChild('loginRef', { static: true }) loginElement!: ElementRef;
  subRef: any;
  @Input() loaderID: string;
  // bsModalRef: BsModalRef;
  constructor(
    public authService_Social: SocialAuthService,
    public notifierService: NotifierService,
    public api_loaedr_false: ApiServiceLoaderService,
    public authService: AuthService,
    public googleButtonService: GoogleButtonService,
    private ngxService: NgxUiLoaderService,
    public ngZone: NgZone,
    public bsModalRef: BsModalRef,
    private global: GlobalVariablesService,
    private router:Router
  ) {}

  ngOnInit(): void {
    this.subscriptions();
    this.callLoginButton();
  }

  subscriptions() {
    this.subRef = this.authService_Social.authState.subscribe((user: any) => {
      if (user) {
        this.callApi(user.authToken, 'facebook');
      }
    });
    //Listen for authorization success
    document.addEventListener('AppleIDSignInOnSuccess', (data: any) => {
      //handle successful response
      if (data) {
        this.callApi(data.detail.authorization.id_token, 'apple');
      }
    });
    //Listen for authorization failures
    document.addEventListener('AppleIDSignInOnFailure', (error) => {
      //handle error.
      this.notifierService.hideAll();
      this.notifierService.notify('error', 'failed');
    });
  }

  callLoginButton() {
   
    this.googleButtonService.auth2.subscribe((auth2) => {
      if (auth2) {
        auth2.attachClickHandler(
          this.loginElement.nativeElement,
          {},
          (googleAuthUser: any) => {
            //googleAuthUser.getBasicProfile();
            this.callApi(
              googleAuthUser.getAuthResponse().access_token,
              'google'
            );
          },
          (error: any) => {
            console.log(error);
            //alert(JSON.stringify(error, undefined, 2));
          }
        );
      }
    });
  }

  signOut(): void {
    this.authService_Social.signOut();
  }

  signInWithFB(): void {
    this.authService_Social.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signInWithApple() {
    document.getElementById('appleid-signin').click();
  }

  callApi(token, from) {
    this.notifierService.hideAll();
    this.api_loaedr_false
      .postData(
        'login/provider',
        {
          token: token,
          from: from,
        },
        false,
        false,
        this.loaderID
      )
      .subscribe(
        (data: any) => {
          this.notifierService.notify('success', data.message);
          this.authService.login(data.object);
          if (this.bsModalRef) this.bsModalRef.hide();
          if (window.screen.width < this.global.md) {
            this.router.navigate(['/mobile/settings']);
          }
        },
        (err: any) => {
          err.error.validation.forEach((element) => {
            this.notifierService.notify('error', element);
          });
        }
      );
  }

  ngOnDestroy() {
    this.subRef.unsubscribe();
    document.removeEventListener('AppleIDSignInOnSuccess', () => {});
    document.removeEventListener('AppleIDSignInOnFailure', () => {});
  }

  manualyStartLoader() {
    this.ngxService.startLoader(this.loaderID);
  }
}
