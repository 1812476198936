import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { fromEvent, NEVER, Observable } from 'rxjs';

// @dynamic
@Injectable({
  providedIn: 'root',
})
export class GlobalVariablesService extends Observable<Event> {
  private readonly resize$: Observable<Event>;
  public currectLocation = new BehaviorSubject(null);
  xl = 1200;
  lg = 992;
  md = 768;
  sm = 576;
  constructor(@Inject(DOCUMENT) { defaultView }: Document) {
    super((subscriber) => this.resize$.subscribe(subscriber));
    this.resize$ = defaultView ? fromEvent(defaultView, 'resize') : NEVER;
  }

  getCustomDateFormat(date: string | number | Date) {
    const myDate = new Date(date);
    const d = myDate.getDate();
    const m = myDate.getMonth() + 1;
    const y = myDate.getFullYear();
    return m + '/' + d + '/' + y;
  }
}
