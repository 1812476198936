import { NotifierService } from 'angular-notifier';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiServiceLoaderService } from 'src/app/modules/core/api-service-loader.service';
import { AuthService } from 'src/app/modules/shared/auth/auth.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['../auth.component.scss', './verify.component.scss'],
})
export class VerifyComponent implements OnInit {
  email: any;
  fromWhere: any;
  picCode: any;
  otpInputConfig = {
    otpLength: 4, // Specify the length of the OTP
    autofocus: true, // Set to true if you want to allow only numbers
    isPasswordInput: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };
  constructor(
    public bsModalRef: BsModalRef,
    private api_loaedr_false: ApiServiceLoaderService,
    private notifierService: NotifierService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.fromWhere == 'login') {
      this.sendCodeAgin();
    }
  }
  verifiedCode() {
    this.notifierService.hideAll();
    this.api_loaedr_false
      .postData(
        'verify/code',
        { code: this.picCode, email: this.email },
        false,
        false,
        'verifyLoader'
      )
      .subscribe(
        (data: any) => {
          this.notifierService.notify('success', data.message);
          this.authService.login(data.object);
          this.bsModalRef.hide();
        },
        (err: any) => {
          err.error.validation.forEach((element) => {
            this.notifierService.notify('error', element);
          });
        }
      );
  }
  sendCodeAgin() {
    this.notifierService.hideAll();
    this.api_loaedr_false
      .postData(
        'resend/code',
        { email: this.email },
        false,
        false,
        'verifyLoader'
      )
      .subscribe(
        (data: any) => {
          this.notifierService.notify('success', data.message);
        },
        (err: any) => {
          err.error.validation.forEach((element) => {
            this.notifierService.notify('error', element);
          });
        }
      );
  }

  pincodeCompleted(code) {
    this.picCode = code;
    this.verifiedCode();
  }

  handeOtpChange(value: string[]): void {
    console.log(value);
  }

  handleFillEvent(value: string): void {
    this.picCode = value;
    this.verifiedCode();
  }

}
