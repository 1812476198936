<form #f="ngForm" class="w-100 formTelephone" [ngClass]="{'errorClass': phoneForm.get('phone').errors && phoneForm.get('phone').touched}"
 [formGroup]="phoneForm" *ngIf="onlyCountriesCodes.length > 0">
  <ngx-intl-tel-input
      [cssClass]="'customStyleInputInternationalTelephone'"
      [enableAutoCountrySelect]="true"
      [enablePlaceholder]="true"
      [searchCountryFlag]="true"
      [searchCountryField]="[
        SearchCountryField.Name
      ]"
      [selectFirstCountry]="false"
      [selectedCountryISO]="CountryISO.SaudiArabia"
      [maxLength]="12"
      [phoneValidation]="true"
      [separateDialCode]="true"
      [numberFormat]="PhoneNumberFormat.National"
      [customPlaceholder]="placeholder"
      [onlyCountries]="onlyCountriesCodes"
      name="phone"
      formControlName="phone"
    >
  </ngx-intl-tel-input>
  <!-- <mat-error class="errorInputNumber" *ngIf="phoneForm.get('phone').errors && phoneForm.get('phone').touched">
    {{'mobile_is_require' |translate}}
  </mat-error> -->
</form>