import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { Subscription } from 'rxjs';
import { ApiServiceLoaderService } from 'src/app/modules/core/api-service-loader.service';
import { LanguagesService } from 'src/app/modules/shared/services/languages.service';

@Component({
  selector: 'app-international-phone-number',
  templateUrl: './international-phone-number.component.html',
  styleUrls: ['./international-phone-number.component.scss']
})
export class InternationalPhoneNumberComponent implements OnInit , OnDestroy {
	@Output() phoneValueChange = new EventEmitter();
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.SaudiArabia];
	phoneForm = new UntypedFormGroup({
		phone: new UntypedFormControl('' , Validators.required)
	});
	sub:Subscription;
	onlyCountriesCodes:any = [];
	maxLength:number = 12;
	placeholder: string;
	constructor(private api_loaedr_false:ApiServiceLoaderService, 
		private translate: TranslateService,
		public translation: LanguagesService
		){}
	ngOnInit(): void {
		this.placeholder = this.translation.getLang == 'ar' ? 'أدخل رقم هاتفك المحمول': 'Enter your Mobile Number'  //this.translate.instant('enter_your_mobile_no');
		this.sub = this.phoneForm.get('phone').valueChanges.subscribe(x=>{
			if(this.phoneForm.get('phone').errors){
				this.phoneValueChange.emit('');	
			}else{
				x.e164Number = x.e164Number.replace('+', '');
				this.phoneValueChange.emit(x);	
			}		
		});
		this.getData();
		var phoneLocalStorage:any = JSON.parse(localStorage.getItem("user_mabaat"));
		if(phoneLocalStorage){
			this.phoneForm.patchValue({
				phone: {
					countryCode: '',
					dialCode: "1111",
					e164Number: "+" + phoneLocalStorage?.mobile,
					internationalNumber: "",
					nationalNumber: "",
					number: phoneLocalStorage?.mobileAfterCode
				}
			  });
			  this.phoneForm.get("phone").markAsTouched();
			  this.phoneForm.get("phone").updateValueAndValidity();
		}
	}
	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}
	getData(){
		this.onlyCountriesCodes = [];
		this.api_loaedr_false.getData('sms/countries').subscribe((data:any) =>{
			data.object.countries.forEach(element => {
				this.onlyCountriesCodes.push((element.code).toLowerCase( ));
			});
		})
	}
}
