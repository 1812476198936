import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonGoogleComponent } from './button-google.component';



@NgModule({
  declarations: [ButtonGoogleComponent],
  imports: [
    CommonModule
  ],
  exports:[
    ButtonGoogleComponent
  ]
})
export class ButtonGoogleModule { }
