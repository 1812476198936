import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paginateArray'
})
export class PaginateArrayPipe implements PipeTransform {

  transform(data: any, numberOfItem:any , activePage:any) {
    let startIndex = (activePage*numberOfItem)-numberOfItem;
    if(startIndex < 0) startIndex = 0;
    
    let arr = [];
   if(data){
    for (let i = startIndex ,y=0; y < numberOfItem; y++,i++) {
      if(data[i]){
        arr.push(data[i]);
      }
    }
    return arr;
   }
   return [];
  }

}
