import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModules } from '../../core/material.exports';
import { TranslateModule } from '@ngx-translate/core';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from "ngx-ui-loader";
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PaginateArrayPipe } from '../../base/pipes/paginate-array.pipe';
import { NearbyExtraFeaturesPipe } from '../../base/pipes/nearby-extra-features.pipe';

@NgModule({
  declarations: [
    PaginateArrayPipe,
    NearbyExtraFeaturesPipe
  ],
  imports: [
    HttpClientModule,
  ],
  exports:[
    MaterialModules,
    TranslateModule,
    NgxUiLoaderModule,
    //NgxUiLoaderHttpModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PaginateArrayPipe,
    NearbyExtraFeaturesPipe
  ],
})
export class SharedModule { }


