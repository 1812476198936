<div style="position: relative;">
    <ngx-ui-loader [loaderId]="'curencyLoader'"></ngx-ui-loader>
        <div class="currency">
            <div class="header">
              <div class="head">
                <div class="d-inline-block">{{"select_your_currency"|translate}}</div>
                <div (click)="bsModalRef.hide()" class="closeBtn"><span class="icon-Icon-ionic-ios-close"></span></div>
              </div>
              <div class="subHead">{{"discover_all_currency"|translate}}</div>
            </div>
            <div class="page text-left">
               <mat-radio-group aria-label="Select an option" [(ngModel)]="currency">
                <div class="head">
                    {{"Suggested for you"|translate}}
                 </div>
                <mat-radio-button [value]="item?.currency" *ngFor="let item of data?.object?.recommended">
                    <div class="text">{{item?.title.substring(0, 20)}}</div>
                    <div class="cur">{{item?.currency}}</div>
                </mat-radio-button>
                <div class="head">
                    {{"All currencies"|translate}}
                 </div>
                  <mat-radio-button [value]="item?.currency" *ngFor="let item of data?.object?.others">
                      <div class="text">{{item?.title.substring(0, 20)}}</div>
                      <div class="cur">{{item?.currency}}</div>
                  </mat-radio-button>
              </mat-radio-group>
        </div>
        <div class="action">
            <button class="ml-auto" (click)="setCurrency()">{{"save"|translate}}</button>
        </div>
    </div>
</div>
  
  
  
  
  
  
  
  
  
  