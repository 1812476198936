import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../../core/api.service';
import { LanguagesService } from '../services/languages.service';
import { ApiServiceLoaderService } from '../../core/api-service-loader.service';
import { GlobalVariablesService } from '../../base/services/global-variables.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userProfileBehaviorSubject = new BehaviorSubject<any>({});
  userProfileBehaviorSubject$ = this.userProfileBehaviorSubject.asObservable();

  constructor(private api:ApiService, private globalService:GlobalVariablesService,
     private api_loaedr_false:ApiServiceLoaderService,private langService:LanguagesService) {
    this.userProfileBehaviorSubject.next(JSON.parse(localStorage.getItem('user_mabaat')))
  }
  get isLoggedIn(): boolean {
    const token = localStorage.getItem('token');
    return (token == null) ? false : true;
  }
  get user(){
    return this.userProfileBehaviorSubject.getValue();
  }
  login(data:any){
    if(data.token){
      localStorage.setItem('token',data.token);
    }
    if(data.user){
      this.saveProfileData(data.user);
    }
  }
  logout(){
    this.api_loaedr_false.postData('logout',{},true,true).subscribe(res =>{
      localStorage.removeItem('token');
      localStorage.removeItem('user_mabaat');
      window.location.reload();
      //this.langService.reloadCurrentRoute();
      this.globalService.currectLocation.next(null);
    })

  }
  saveProfileData(data:any){
    navigator.geolocation.getCurrentPosition((position) => {
      let pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      this.globalService.currectLocation.next(pos);
    })
    localStorage.setItem('user_mabaat',JSON.stringify(data));
    this.userProfileBehaviorSubject.next(data)
  }
}
