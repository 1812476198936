import { Router, NavigationStart } from '@angular/router';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
  AfterViewInit,
  Input,
} from '@angular/core';
import { GlobalVariablesService } from '../services/global-variables.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/modules/shared/auth/auth.service';
import { LanguagesService } from 'src/app/modules/shared/services/languages.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { HrefIDsService } from '../services/href-ids.service';
import { LoginComponent } from '../allModals/authentication/login-modal/login.component';
import { SigninComponent } from '../allModals/authentication/signin-modal/signin.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ChangeLanguageComponent } from '../allModals/change-language/change-language.component';
import { CurrencyComponent } from '../allModals/currency/currency/currency.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  providers: [SocialAuthService],
})
export class ToolbarComponent implements OnInit, AfterViewInit {
  @Output() drawerValueChange = new EventEmitter();
  @ViewChild('toolbar', { static: false }) toolbar?: ElementRef<HTMLElement>;
  @ViewChild('icon', { static: false }) icon?: ElementRef<HTMLElement>;
  @Input() toolbarBoolean: any = true;
  @Input() toolbarBooleanMobile: any = true;

  @Input() appBarBoolean: boolean = true;
  checkRoutLink: string = this.router.url;
  bsModalRef: BsModalRef;
  direction: any;
  userProfile: any = [];
  typeOfScreen: any;
  @Input() scrollable: boolean = true;
  get currency(): String {
    return localStorage.getItem('currency');
  }
  constructor(
    public authService: AuthService,
    private renderer: Renderer2,
    public router: Router,
    private global: GlobalVariablesService,
    private modalService: BsModalService,
    public lang: LanguagesService,
    public authService_Social: SocialAuthService,
    private _bottomSheet: MatBottomSheet,
    private hrefIDsService: HrefIDsService
  ) {
    if (window.screen.width < this.global.md) {
      this.typeOfScreen = 'sm';
    }
  }
  ngOnInit(): void {
    this.authService.userProfileBehaviorSubject$.subscribe((data) => {
      this.userProfile = data;
    });
    this.lang.getCurrentLanguage().subscribe((data) => {
      this.direction = data.dir;
    });
  }

  iOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      navigator.userAgent.includes('Mac')
    );
  }

  test() {}

  ngAfterViewInit() {
    if (
      (this.router.url == '/' ||
        this.router.url == '/home' ||
        this.router.url == '/career' ||
        this.router.url == '/partner') &&
      this.scrollable
    ) {
      this.scrollOrNot(true);
    } else {
      this.scrollOrNot(false);
    }
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (
          (val.url == '/' ||
            val.url == '/home' ||
            val.url == '/career' ||
            val.url == '/partner') &&
          this.scrollable
        ) {
          this.scrollOrNot(true);
        } else {
          this.scrollOrNot(false);
        }
      }
    });
  }
  changeLanguage(l: string) {
    this.lang.changeLanguage(l);
  }
  scrollOrNot(scroll: boolean) {
    if (scroll) {
      if (
        document.body.scrollTop > 30 ||
        document.documentElement.scrollTop > 30
      ) {
        this.afterScroll();
      } else if (
        document.body.scrollTop <= 30 ||
        document.documentElement.scrollTop <= 30
      ) {
        this.beforeScroll();
      }
      window.addEventListener('scroll', (e) => {
        if (
          document.body.scrollTop > 30 ||
          document.documentElement.scrollTop > 30
        ) {
          this.afterScroll();
        } else if (
          document.body.scrollTop <= 30 ||
          document.documentElement.scrollTop <= 30
        ) {
          this.beforeScroll();
        }
      });
    } else {
      this.afterScroll();
      window.addEventListener(
        'scroll',
        (e) => {
          this.afterScroll();
        },
        false
      );
    }
  }
  beforeScroll() {
    this.renderer.removeClass(this.toolbar?.nativeElement, 'reDesign');
    if (this.direction == 'ltr') {
      this.renderer.removeClass(
        this.icon?.nativeElement,
        'icon-Mabaat---Green-Logo'
      );
      this.renderer.addClass(
        this.icon?.nativeElement,
        'icon-Mabaat---White-Logo'
      );
    } else if (this.direction == 'rtl') {
      this.renderer.removeClass(
        this.icon?.nativeElement,
        'icon-header_logo_ar_green'
      );
      this.renderer.addClass(this.icon?.nativeElement, 'icon-tsXl5L01');
    }
  }
  afterScroll() {
    this.renderer.addClass(this.toolbar?.nativeElement, 'reDesign');
    if (this.direction == 'ltr') {
      this.renderer.removeClass(
        this.icon?.nativeElement,
        'icon-Mabaat---White-Logo'
      );
      this.renderer.addClass(
        this.icon?.nativeElement,
        'icon-Mabaat---Green-Logo'
      );
    } else if (this.direction == 'rtl') {
      this.renderer.removeClass(this.icon?.nativeElement, 'icon-tsXl5L01');
      this.renderer.addClass(
        this.icon?.nativeElement,
        'icon-header_logo_ar_green'
      );
    }
  }
  scrollToTop() {
    this.hrefIDsService.send(true);
    window.scroll(0, 0);
  }
  drawerToggle() {
    this.drawerValueChange.emit('4444');
  }
  openModalLogin() {
    this.bsModalRef = this.modalService.show(LoginComponent, {
      class: 'authModal',
    });
  }
  openModalSignin() {
    this.bsModalRef = this.modalService.show(SigninComponent, {
      class: 'authModal',
    });
  }

  openModalCurrency() {
    this.bsModalRef = this.modalService.show(CurrencyComponent, {
      class: 'propertyDateModal',
    });
  }

  openBottomSheetChangeLanguage(): void {
    this._bottomSheet.open(ChangeLanguageComponent, {
      panelClass: 'sheetBottom',
    });
  }
}
