import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaDataService {
  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document
  ) {
    setTimeout(() => {
      const metaArray = [
        {
          name: 'appleid-signin-client-id',
          content: 'com.mabaat.app',
        },
        {
          name: 'appleid-signin-scope',
          content: 'name email',
        },
        {
          name: 'appleid-signin-redirect-uri',
          content: 'https://www.mabaat.com',
        },
        {
          name: 'appleid-signin-use-popup',
          content: 'true',
        },
        {
          name: 'facebook-domain-verification',
          content: 'zf96el4li667p6oar4ul5i7rqtu1p6',
        },
      ];
      this.meta.addTags(metaArray);

      var appleScript = this.document.createElement('script');
      appleScript.src =
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
      this.document.body.appendChild(appleScript);

      var tabbyScript = this.document.createElement('script');
      tabbyScript.src =
        'https://checkout.tabby.ai/tabby-promo.js';
      this.document.body.appendChild(tabbyScript);

      var gtgNoScript = this.document.createElement('noscript');
      var gtgIframe = this.document.createElement('iframe');
      gtgIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-TDDWHP6';
      gtgIframe.height = '0';
      gtgIframe.width = '0';
      gtgIframe.style = 'display: none; visibility: hidden';
      gtgNoScript.appendChild(gtgIframe);
      this.document.body.appendChild(gtgNoScript);

      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-TDDWHP6');
    }, 5000);
  }

  addMetaData(title: string, metaArray: any) {
    this.title.setTitle(title);
    for (let i = 0; i < metaArray.length; i++) {
      this.meta.updateTag(metaArray[i]);
    }
  }

  preloadImage(desktopImg, mobileImg) {
    let mobileLink = this.document.createElement('link');
    mobileLink.rel = 'preload';
    mobileLink.as = 'image';
    mobileLink.href = mobileImg;
    mobileLink.media = 'screen and (max-width: 576px)';
    let desktopLink = this.document.createElement('link');
    desktopLink.rel = 'preload';
    desktopLink.as = 'image';
    desktopLink.href = desktopImg;
    desktopLink.media = 'screen and (min-width: 577px)';

    this.document.head.appendChild(desktopLink);
    this.document.head.appendChild(mobileLink);
  }
}
