import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { GlobalVariablesService } from './modules/base/services/global-variables.service';
import { MetaDataService } from './modules/base/services/meta-data.service';
import { AuthService } from './modules/shared/auth/auth.service';
import { DOCUMENT } from '@angular/common';
import { ApiServiceLoaderService } from './modules/core/api-service-loader.service';
import { NotifierService } from 'angular-notifier';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { GoogleButtonService } from './modules/shared/services/google-button.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild('googleRef', { static: true }) googleRef: ElementRef;
  auth2: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private auth: AuthService,
    private meta: MetaDataService,
    private globalService: GlobalVariablesService,
    public api_loaedr_false: ApiServiceLoaderService,
    public notifierService: NotifierService,
    public authService_Social: SocialAuthService,
    public authService: AuthService,
    public googleButtonService: GoogleButtonService
  ) {
    let arr = [
      {
        name: 'keywords',
        content: 'Mabaat,home,properties',
      },
      {
        name: 'description',
        content:
          'Mabaat is a signature short-term rent platform. Our properties are designed to make you feel comfortable and you are at your own place. We have an eclectic selection of properties and complete services, to immerse you in a beautiful hospitality experience. So you can enjoy your stay at the fullest.',
      },
      {
        name: 'format-detection',
        content: 'email=info@mabaat.com',
      },
      {
        name: 'format-detection',
        content: 'telephone=920023564',
      },
    ];
    this.meta.addMetaData('', arr);
    if (!localStorage.getItem('currency')) {
      localStorage.setItem('currency', 'SAR');
    }
  }
  ngOnInit() {
    this.googleAuthSDK();
    this.loadStyle();
    if (this.auth.isLoggedIn) {
      navigator.geolocation.getCurrentPosition((position) => {
        let pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.globalService.currectLocation.next(pos);
      });
    }
  }

  ngAfterViewInit(): void {
    this.googleButtonService.sahredGoogleButtonGlobal.next(
      this.googleRef.nativeElement
    );
  }

  loadStyle() {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'styles_lazy'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = 'styles_lazy.css';
    } else {
      const style = this.document.createElement('link');
      style.id = 'styles_lazy';
      style.rel = 'stylesheet';
      style.href = `${'styles_lazy.css'}`;

      head.appendChild(style);
    }
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  async googleAuthSDK() {
    (<any>window)['googleSDKLoaded'] = () => {
      (<any>window)['gapi'].load('auth2', () => {
        this.googleButtonService.auth2.next(
          (<any>window)['gapi'].auth2.init({
            // client_id:
            //   '413635543755-veju9apbs6i9195gp8vvk23lgflm9c3i.apps.googleusercontent.com',
            client_id: '22573655509-v0ol7vt8qv3v8tosprnn0l8u7ji02gsj.apps.googleusercontent.com',
            cookiepolicy: 'single_host_origin',
            scope: 'profile email',
            plugin_name:'sample_pwa_login',
            clientSecret: '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',
          })
        );
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement('script');
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
      fjs?.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
  }
}
