import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiServiceLoaderService } from 'src/app/modules/core/api-service-loader.service';
import { LoginComponent } from '../login-modal/login.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: UntypedFormGroup;
  code: any;
  forgetPasswordFormRef: any;
  constructor(
    public bsModalRef: BsModalRef,
    private api_loaedr_false: ApiServiceLoaderService,
    private notifierService: NotifierService,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
  }

  get changePasswordFormRef() {
    return this.changePasswordForm.controls;
  }

  changePassword() {
    this.notifierService.hideAll();
    let obj: any = {};
    obj['code'] = this.code;
    obj['password'] = this.changePasswordFormRef.password.value;
    obj['password_confirmation'] =
      this.changePasswordFormRef.confirmPassword.value;
    if (this.forgetPasswordFormRef.type.value == 'email') {
      obj['email'] = this.forgetPasswordFormRef.email.value;
    } else if (this.forgetPasswordFormRef.type.value == 'mobile') {
      obj['mobile'] = this.forgetPasswordFormRef.mobile.value.toString();
    }
    this.api_loaedr_false
      .postData(
        'change/forgot/password',
        obj,
        false,
        false,
        'changePasswordLoader'
      )
      .subscribe(
        (data: any) => {
          this.notifierService.notify('success', data.message);
          this.openModalLogin();
        },
        (err: any) => {
          err.error.validation.forEach((element) => {
            this.notifierService.notify('error', element);
          });
        }
      );
  }

  openModalLogin() {
    this.bsModalRef.hide();
    this.bsModalRef = this.modalService.show(LoginComponent, {
      class: 'authModal',
    });
  }

  pincodeCompleted(code) {
    this.changePasswordFormRef.code.setValue(code);
  }
}
