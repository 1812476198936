import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { RedirectGuard } from './modules/shared/auth/redirect.guard';

const routes: Routes = [
  { path: 'payment', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'mobile', loadChildren: () => import('./modules/mobile/mobile.module').then(m => m.MobileModule) },
  { path: '', loadChildren: () => import('./modules/base/base.module').then(m => m.BaseModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    useHash: false,
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
