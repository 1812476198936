import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleButtonService {
  sahredGoogleButton:BehaviorSubject<any> = new BehaviorSubject(null);

  sahredGoogleButtonGlobal:BehaviorSubject<any> = new BehaviorSubject(null);
  auth2:BehaviorSubject<any> = new BehaviorSubject(null);
  
  constructor() { }
}
