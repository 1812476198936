<!-- <div class="d-flex social mb-3" (click)="signInWithFB()">
    <div class="order-1">
      <span class="icon-Icon-awesome-facebook-square" style="color: #4267b2; font-size: 20px;"></span>
    </div>
    <div class="order-2 ml-auto text">{{"conti_facebook"|translate}}</div>
    <div class="order-3 ml-auto"></div>
  </div>
  <div class="d-flex social mb-3" (click)="signInWithApple()">
    <div class="order-1">
      <span class="icon-appleinc" style="font-size: 20px;"></span>
    </div>
    <div class="order-2 ml-auto text">{{"conti_apple"|translate}}</div>
    <div class="order-3 ml-auto"></div>
  </div> -->

  <!-- Commented by Zeeshan, please confirm before delete -->

  <div class="d-flex social" #loginRef (click)="manualyStartLoader()">
    <div class="order-1">
      <span class="icon-google" style="color: #f14336; font-size: 20px;"></span>
    </div>
    <div class="order-2 ml-auto text">{{"conti_google"|translate}}</div>
    <div class="order-3 ml-auto"></div>
  </div>